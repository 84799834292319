import { MetaFunction, useRouteLoaderData, useNavigate, useLocation } from "@remix-run/react";
import { Button } from "~/components/ui/button";
import type { RootLoaderData } from "~/root";
import { IconFooter } from "~/components/layout/IconFooter";
import { generateMeta } from "~/utils/meta";

export const meta: MetaFunction = ({ matches }) => {
    const rootData = matches[0].data as RootLoaderData;

    return generateMeta({
        title: "About",
        description: "Discover and connect with relevant people at professional events effortlessly with our SaaS tool. Enhance your networking and sales experiences and make meaningful connections with ease. Try it now!",
        keywords: "Professional events, SDR tool, event networking, find relevant people, event connections, professional networking software, sales research tool",
        baseUrl: rootData?.baseUrl ? `${rootData.baseUrl}/about` : undefined,
        appTitle: rootData?.appTitle,
    });
};

export default function About() {
    const rootData = useRouteLoaderData<RootLoaderData>("root");
    const appTitle = rootData?.appTitle ?? "Our Platform";
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="flex flex-col min-h-screen bg-white">
            <main className="flex-1 overflow-y-auto animate-in fade-in duration-700 slide-in-from-bottom-4">
                {/* Hero Section */}
                <section className="relative h-screen flex items-center justify-center bg-gradient-to-b from-gray-50 to-white">
                    <div className="text-center px-4 max-w-4xl mx-auto">
                        <h1 className="text-6xl font-semibold tracking-tight mb-6">
                            Meet the right people.
                            <br />
                            Every time.
                        </h1>
                        <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
                            {`${appTitle} is a luma plugin that ranks guests based on your goals. Fundraise, sell, and grow your business with ${appTitle}.`}
                        </p>
                        <a href="/login">
                            <Button variant="landing" className="px-8 py-4 text-lg">
                                Get Started
                            </Button>
                        </a>
                    </div>
                </section>

                {/* Feature Section */}
                <section className="py-32 px-4 bg-black text-white">
                    <div className="max-w-6xl mx-auto">
                        <h2 className="text-5xl font-semibold text-center mb-24">How it works</h2>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
                            <div className="text-center">
                                <div className="text-3xl mb-4">1. Upload</div>
                                <p className="text-gray-400">Simply provide the event page or guest list. We handle the rest.</p>
                            </div>
                            <div className="text-center">
                                <div className="text-3xl mb-4">2. Enrich</div>
                                <p className="text-gray-400">We create detailed professional profiles with relevant tags.</p>
                            </div>
                            <div className="text-center">
                                <div className="text-3xl mb-4">3. Connect</div>
                                <p className="text-gray-400">Connect with the right people during the event.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Pricing Section */}

                {/* Pricing Section */}
                <section className="py-32 px-4 bg-gray-50">
                    <div className="max-w-6xl mx-auto text-center">
                        <h2 className="text-5xl font-semibold mb-4">Simple pricing</h2>
                        <p className="text-xl text-gray-600 mb-16 max-w-2xl mx-auto">Choose the plan that best fits your needs. All plans include core features.</p>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
                            {/* Free Plan */}
                            <div className="rounded-3xl p-8 bg-white border border-gray-200 hover:border-gray-300 hover:shadow-lg transition-all relative flex flex-col">
                                <div className="mb-8">
                                    <h3 className="text-2xl font-semibold mb-2">Free</h3>
                                    <p className="text-gray-600 mb-4">Perfect for getting started</p>
                                    <div className="flex items-end justify-center mb-4">
                                        <span className="text-6xl font-bold">$0</span>
                                        <span className="text-xl text-gray-500 mb-2">/mo</span>
                                    </div>
                                </div>
                                <ul className="text-left space-y-4 mb-8 flex-grow">
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-green-500 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Up to 50 guests
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-green-500 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Mobile directory
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-green-500 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Spreadsheet export
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-green-500 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Basic search
                                    </li>
                                </ul>
                                <a href="/login" className="block w-full">
                                    <Button variant="outline" className="w-full rounded-full py-6">Start Free</Button>
                                </a>
                            </div>

                            {/* Pro Plan */}
                            <div className="rounded-3xl p-8 bg-black text-white hover:shadow-xl transition-all relative flex flex-col transform hover:-translate-y-1">
                                <div className="absolute -top-3 left-1/2 transform -translate-x-1/2 z-10">
                                    <div className="bg-gradient-to-r from-blue-500 via-blue-400 to-purple-500 text-white px-4 py-1 rounded-full text-sm font-medium shadow-md flex items-center justify-center whitespace-nowrap">
                                        <span className="inline-flex items-center gap-1">
                                            MOST POPULAR
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-8">
                                    <h3 className="text-2xl font-semibold mb-2">Guest</h3>
                                    <p className="text-gray-400 mb-4">For event guests</p>
                                    <div className="flex items-end justify-center mb-4">
                                        <span className="text-6xl font-bold">$50</span>
                                        <span className="text-xl text-gray-400 mb-2">/mo</span>
                                    </div>
                                </div>
                                <ul className="text-left space-y-4 mb-8 flex-grow">
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-blue-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Up to 1,000 guests
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-blue-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Enhanced mobile directory
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-blue-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Data export options
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-blue-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Smart guest ranking
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-blue-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Priority support
                                    </li>
                                </ul>
                                <a href="/login" className="block w-full">
                                    <Button className="w-full bg-white text-black hover:bg-gray-100 rounded-full py-6">Get Guest Access</Button>
                                </a>
                            </div>

                            {/* Enterprise Plan */}
                            <div className="rounded-3xl p-8 bg-gradient-to-br from-gray-900 to-black text-white hover:shadow-xl transition-all relative flex flex-col">
                                <div className="mb-8">
                                    <h3 className="text-2xl font-semibold mb-2">Host</h3>
                                    <p className="text-gray-400 mb-4">For event hosts</p>
                                    <div className="flex items-end justify-center mb-4">
                                        <span className="text-6xl font-bold">$200</span>
                                        <span className="text-xl text-gray-400 mb-2">/mo</span>
                                    </div>
                                </div>
                                <ul className="text-left space-y-4 mb-8 flex-grow">
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-purple-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Up to 10,000 guests
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-purple-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Custom guest directories
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-purple-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Dedicated support
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-purple-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        API access
                                    </li>
                                    <li className="flex items-start">
                                        <svg className="w-5 h-5 text-purple-400 mr-2 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        Custom integrations
                                    </li>
                                </ul>
                                <a href="/login" className="block w-full">
                                    <Button className="w-full bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 text-white rounded-full py-6">Get Host Access</Button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <IconFooter
                icons={[
                    { id: 'explore', route: '/explore' },
                    { id: 'about', route: '/about' },
                    { id: 'support', route: '/support' },
                    { id: 'login', route: '/login' }
                ]}
                selectedIcon="about"
                navigate={navigate}
                location={location}
            />
        </div>
    );
}
